import React from 'react';
import '../../App.css';
import Dashboard from '../Dashboard';

function Home(){
    return(
        <>
            <Dashboard/>
        </>
    );
}

export default Home;