import axios from 'axios'

class MapsService {

    getTrackingData(username)
    {
      return axios.get(`http://185.146.87.75:8080/radioactivitate/userLocationTracker/getTrackingData/${username}`, {username})
    }

    getTrackingDataByDay(username,day,month,year)
    {
      return axios.get(`http://localhost:8080/userLocationTracker/getTrackingDataByDay/${username}/${day}/${month}/${year}`, {username,day,month,year})
    }

    getDataByTodaysTimestamp()
    {
      return axios.get(`http://185.146.87.75:8080/radioactivitate/sensor/getDataByTimestampForDailyChart`)
    }

    addressToCoordinates(address){
      return axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/Bulevardul%20Culturii%207%2C%20Campina.json?country=ro&bbox=-180%2C-90%2C180%2C90&limit=2&proximity=-73.990593%2C40.740121&types=address&language=ro&access_token=pk.eyJ1Ijoic2FiaW5zajA3IiwiYSI6ImNrdXF3ejhxMDJsbXIyd282eGZxMHNwdmEifQ.cva-Ejcp-lyywMVzUpz8zg`)
    }
}

export default new MapsService()